import React from 'react'
import Meseros from '../../Components/Meseros/Meseros'
export default function MeserosPage() {
    return (
        <div>
            <Meseros />

        </div>
    )
}
